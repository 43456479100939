<template>
  <div class="vx-row mb-12">
     <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
    <vs-button class="mb-2" v-on:click="createPayment">Create</vs-button>

    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="thead">
        <th width="7%">
        Action
        </th>
        <th width="7%">
        Check
        </th>
      </template>
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.purchasePaymentProposal"  :class="tr.class">
          <vs-td class="whitespace-no-wrap">
              <template >
                <div class="mt-4 flex justify-between">
                <vx-tooltip text="Cancel Payment Proposal" class="mr-4">
                  <vs-button
                    type="line"
                    color="red"
                    icon-pack="feather"
                    icon="icon-x"
                    v-on:click.stop="doCancel(tr)"
                  />
                </vx-tooltip>
                <vx-tooltip text="Show Invoice" class="mr-4">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-on:click.stop="
                      handleShow(tr)"
                  />
                </vx-tooltip>
                </div>
              </template>
            </vs-td>
          <vs-td>
            <vs-checkbox
              :checked="checked.includes(data[indextr].ID)"
              v-on:click="addChecked(data[indextr])"
              >Check</vs-checkbox
            >
          </vs-td>
          <vs-td>
            {{ tr.Code }}
          </vs-td>
          <vs-td>
            Code : {{ tr.SupplierCode }}<br>
            Name : {{ tr.SupplierName }}
          </vs-td>
          <vs-td>
            {{ tr.DeliveryNumber }}
          </vs-td>
          <vs-td>
            External Number : {{tr.PurchaseInvoiceCode}}<br>
            Internal Number : {{tr.PurchaseInvoiceCodeInternal }}<br>
            Total Value : {{priceFormat(tr.PurchaseInvoiceValue)}}<br>
            Due Date : {{ dateFormat(tr.DueDate) }}<br>
            Plan Payment Date : {{ dateFormat(tr.PurposePaymentDate) }} <br />
              Type :
              {{ tr.po_type }}
          </vs-td>
          <vs-td>
          {{priceFormat(tr.PaidValue)}}<br>
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
    <!-- Approve Prompt -->
    <vs-prompt
      title="Confirmation"
      color="primary"
      @accept="doYes"
      @cancel="doNo"
      @close="closeDetail"
      :accept-text="'Yes'"
      :cancel-text="'No'"
      :buttons-hidden="false"
      :active.sync="confirmation"
    >
      <div class="con-exemple-prompt">
        Create Payment
        <br />
        
        Process due date only?
        <br />
        <br />
        <!-- <vs-textarea v-model="notes"/> -->
      </div>
    </vs-prompt>
    </div>
      <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <detail-div @close="handleClose" :selected="selectedData" />
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import detail from "./detail.vue"
import moment from "moment"
// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div" : detail
  },
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      checked:[],
      checkedCode: [],
      confirmation: false,
      header: [
        {
          text: "Code",
          value: "code",
          // width: '5%'
        },
        {
          text: "Supplier",
          value: "supplier_code",
        },
        {
          text: "No Shipment",
          value: "delivery_number",
        },
        {
          text: "Invoice",
          value: "purchase_invoice_code",
        },
        {
          text: "Value",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    // this.reloadData(this.params);

  },
  methods: {
    doCancel(selected) {
      // console.log(selected)
      this.$vs.loading();
      const params = {
        "status" : 3,
        "propose_id" : selected.ID,
        "purchase_invoice_id" : selected.PurchaseInvoiceID,
        "notes": ""
      }
      
      this.$http.post('/api/v1/purchase-payment-proposal/change-status',params)
      .then((resp) => {
        this.$vs.loading.close();
        if (resp.code == 200) {
          this.handleClose();
          
          this.$vs.notify({
            color: "success",
            title: "Success",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
      }).catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e)
      })

    },
    doYes() {
      this.checkedCode.map(v => {
        if (parseInt(moment(v.DueDate).format('YYYYMMDD')) > parseInt(moment().format('YYYYMMDD'))) {
          this.checked = this.checked.filter(subv => {return v.ID != subv})
        }
      })
      if (this.checked.length > 0) {
        this.doGenerate()
      } else {
        this.$vs.notify({
          color: "danger",
          title: "not found due date only on selected data",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
      // console.log(this.checked)
      
    },
    doNo() {
      this.doGenerate()
      // console.log('no')
    },
    doGenerate() {
      let params = {
          proposal_id: this.checked
      }
      this.$vs.loading();
      this.$http.post('/api/v1/purchase-payment-request/generate',params).then((r) => {
        // console.log(r)
        this.$vs.loading.close();
        if (r.code == 200) {
          this.$vs.notify({
            color: "success",
            title: "Success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        } else {
          this.$vs.notify({
            color: "danger",
            title: r.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
        this.$nextTick(() => {
          this.checked = []
          this.checkedCode = []
          this.reloadData(this.params)
        });
      }).catch((e) => {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.$vs.loading.close();
      })
    },
    addChecked(val) {
      console.log(this.checked.length);
      if (this.checked.includes(val.ID)) {
        this.checked.splice(this.checked.indexOf(val.ID), 1);
        this.checkedCode = this.checkedCode.filter(v => {
          return v.ID != val.ID
        })
        // this.checkedCode.splice(this.checkedCode.indexOf(val.Code), 1);
      } else {
        this.checked.push(val.ID);
        this.checkedCode.push(val);
      }
    },
    createPayment() {
      this.confirmation = true
    },
    handleApprove(selected){
      selected.class =  "highlight"
      this.selectedData = selected
      this.rejectPrompt = false
      this.approvalPrompt = true
    },
    dateFormat(date){
      return moment(date).format('YYYY-MM-DD')
    },
    handleReject(selected){
      selected.class =  "highlight"
      this.selectedData = selected
      this.approvalPrompt = false
      this.rejectPrompt = true
    },
    approve(){
      console.log(this.selectedData)
    },
    closeDetail() {
      this.activePrompt2 = false;
      this.detail = false;
      this.selectedData.class = ""
      this.selectedData = {
        ID: 0,
      };
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http.get('/api/v1/purchase-payment-proposal',{
            params: {
                status: 1,
                still_empty: 1,
                search: params.search,
                length: params.length,
                page: params.page,
                order: params.sort,
                sort: params.order,
            }
        }).then((r) => {
          resolve(r)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    handleShow(selected) {
      this.selectedData.class = ""
      selected.class =  "highlight"
      this.selectedData = selected
      this.detail = true
      
    },
    handleClose() {
      this.selectedData.class = ""
      this.detail = false
      this.selectedData = {
        ID: 0,
      };
      this.reloadData(this.params)
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.purchasePaymentProposal;
          this.responseData = r.data;
          this.responseData.length = r.data.purchasePaymentProposal.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.highlight > td {
  background-color: #d0cdf0;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-dialog {
  max-width: 1000px;
}
</style>
<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Detail Payment</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier</label>
        <multiselect
          class="selectExample"
          v-model="supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="true"
        >
          <!-- <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template> -->
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Proposal</label>
        <multiselect
          class="selectExample"
          v-model="paymentProposal"
          :options="optionPaymentProposal"
          :multiple="true"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Code"
          @search-change="getOptionPaymentProposal"
          :disabled="true"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Code }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Code }}</span>
              <br>
              <span class="option__desc"> Due Date : {{ dateFormat(dt.option.DueDate)}}</span>
              <br>
              <span class="option__desc"> Paid Proposal ({{ priceFormat(dt.option.PaidValue)}})</span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <template v-for="proposal in paymentProposal">
          <div v-bind:key="proposal.ID">
            <vs-divider style="width: 100%;"><b>{{proposal.Code}}</b></vs-divider>
            Code : {{proposal.PurchaseInvoiceCode}}
            <feather-icon v-on:click="showInvoice(proposal.PurchaseInvoiceID)" icon="InfoIcon" svgClasses="h-4 w-4 clickable" /><br>
            Due Date : {{dateFormat(proposal.DueDate)}}<br>
            Plan Payment Date : {{ dateFormat(proposal.PurposePaymentDate)}}<br>
            Paid Proposal : {{priceFormat(proposal.PaidValue)}}<br>
            <vs-divider style="width: 100%;">Attachment</vs-divider>
            <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
              <table class="vs-table vs-table--tbody-table">
                <template v-for="(tr, i) in piAttachment(proposal.ID)">
                  <tr
                    class="tr-values vs-table--tr tr-table-state-null selected"
                    v-bind:key="tr.name"
                  >
                    <td class="td vs-table--td">{{ tr.name }}</td>
                    <td class="td vs-table--td">
                      <vx-tooltip text="Show" v-if="tr.path != ''">
                        <vs-button
                          type="line"
                          icon-pack="feather"
                          @click.stop="downloadFileAwsS3(tr.path)"
                          icon="icon-eye"
                        />
                      </vx-tooltip>
                    </td>
                    <td class="td vs-table--td">
                      <template>
                        <vx-tooltip text="Delete">
                          <vs-button
                            type="line"
                            icon-pack="feather"
                            @click.stop="handleDeleteAttachment(i, tr.id)"
                            icon="icon-trash"
                          />
                        </vx-tooltip>
                      </template>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
            <vs-divider style="width: 100%;">Note</vs-divider>
            <template v-for="note in piNotes(proposal.ID)">
              <b v-bind:key="note">{{note}}
                <br>
              </b>
            </template>
            <vs-divider style="width: 100%;">Comment</vs-divider>
            {{piComment(proposal.ID)}}
            
            <vs-divider style="width: 100%;"></vs-divider>
            <vs-divider style="width: 100%;"></vs-divider>

          </div>
        </template>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Operating Unit Bank</label>
        <multiselect
          class="selectExample"
          v-model="operatingUnitBank"
          :options="optionBankOperatingUnit"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
               bank.filter(v => {
                  return v.ID == dt.option.BankID
                })[0].Name + ' ' + dt.option.AccountNumber + ' (' + dt.option.AccountName + ')'
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                bank.filter(v => {
                  return v.ID == dt.option.BankID
                })[0].Name + ' ' + dt.option.AccountNumber + ' (' + dt.option.AccountName + ')'
              }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier Bank</label>
        <multiselect
          class="selectExample"
          v-model="supplierBank"
          :options="optionBankSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="BankName"
          :disabled="true"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{dt.option.BankName}} {{dt.option.AccountNumber}} ({{dt.option.AccountName}})
               </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{dt.option.BankName}} {{dt.option.AccountNumber}} ({{dt.option.AccountName}})
               </span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-full mb-5">
        <vs-input
          class="w-full"
          label="Reference Code"
          name="Reference Code"
          v-model="reference_code"
        />
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Transaction Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="date_clearing"
          placeholder="Date Clearing"
          :disabled-dates="{from: new Date()}"
        ></datepicker>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"><b>Notes</b></vs-divider>
      <template v-for="note in notes">
        <b v-bind:key="note">{{note}}
          <br>
        </b>
      </template>
      <vs-textarea
        v-model="new_notes"
      />
      <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png .pdf"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        >List Attachment</vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr,i) in fileAttachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.name"
            >
              <td class="td vs-table--td">{{ tr.name }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.path != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="downloadFileAwsS3(tr.path)"
                  />
                </vx-tooltip>
              </td>
              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="handleDeleteAttachment(i,tr.id)"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <div class="vx-col w-full" v-if="selected.CountPrint > 0">
        <br>
        <vs-button class="mb-2" v-on:click="handleOUBank">Save OU Bank</vs-button>
        &nbsp;
        <vs-button class="mb-2" v-on:click="handleSubmit">Confirm Payment</vs-button>

        <vs-button color="red" class="mb-2 ml-2" v-on:click="handleFailed">Failed Payment</vs-button>
      </div>
      <div v-else>
        <vs-button class="mb-2" v-on:click="handleOUBank">Save OU Bank</vs-button>

        <vs-button color="red" class="mb-2 ml-2" v-on:click="handleFailed">Cancel Payment</vs-button>

      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment"

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return this.initialState();
  },
  props: {
      selected: Object,
  },
  methods: {
    initialState() {
      return {
        file:[],
        fileAttachment:[],
        search: "",
        supplier: null,
        operatingUnitBank: null,
        supplierBank: null,
        bank: [],
        paymentProposal: [],
        optionSupplier: [],
        optionPaymentProposal: [],
        optionBankOperatingUnit: [],
        optionBankSupplier: [],
        notes:[],
        purchaseInvoice: [],
        reference_code:"",
        date_clearing:"",
        new_notes:"",
      };
    },
    piComment(id) {
      let pi = this.purchaseInvoice.filter(function (pi) {
                    return pi.ID == id;
                  })
      let comment = ""
      if (pi.length > 0) {
        comment = pi[0].purchaseInvoice.CommentInvoice
      }
      return comment
    },
     piNotes(id) {
      let pi = this.purchaseInvoice.filter(function (pi) {
                    return pi.ID == id;
                  })
      let notes = []
      if (pi.length > 0) {
        notes = pi[0].purchaseInvoice.Notes.toString().split(";")
      }
      return notes
    }, 
    showInvoice(id){
       this.$emit("detailPurchaseInvoice",id);
    },
    piAttachment(id) {
      let pi = this.purchaseInvoice.filter(function (pi) {
                    return pi.ID == id;
                  })
      let attachment = []
      if (pi.length > 0) {
        attachment = pi[0].attachment
      }
      return attachment
    },
    handleOUBank() {
      if (this.operatingUnitBank == null){
         this.$vs.notify({
          title: "Error",
          text: "please select operating unit bank first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return
      }
      const params = {
        id:this.selected.ID,
        bank_id: this.operatingUnitBank.BankID,
        bank_name: this.bank.filter(v => {return this.operatingUnitBank.BankID == v.ID})[0].Name,
        account_number: this.operatingUnitBank.AccountNumber,
        account_name: this.operatingUnitBank.AccountName,
        account_branch: this.operatingUnitBank.BranchName,
        type_update: "OuBank"
      }
      this.$vs.loading();
      //console.log(params)
      this.$http.post('/api/v1/purchase-payment-request/update',params)
        .then(result => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose()
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }).catch(e => {

        })
    },
    handleSubmit() {
      if (this.paymentProposal.length < 1) {
        this.$vs.notify({
          title: "Error",
          text: "please select payment proposal first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return
      }
      if (this.operatingUnitBank == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select operating unit bank first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return
      }
      if (this.reference_code == "" || this.reference_code == null) {
        this.$vs.notify({
          title: "Error",
          text: "reference code can not be empty",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return
      }
      
      // //console.log(this.operatingUnitBank,this.supplierBank)
      
      let line = []
      let total = parseFloat(0)
      this.paymentProposal.map(v => {
        total += parseFloat(v.PaidValue)
        line.push({
          proposal_id: v.ID,
          code: v.Code
        })
      })
      const params = {
        id:this.selected.ID,
        purchase_payment_request_id: this.selected.ID,
        status_purchase_request: 3,
        reference_code:this.reference_code,
        notes: this.new_notes,
        clearing_date: moment(this.date_clearing).format('YYYY-MM-DD'),
      }
      this.$vs.loading();
      //console.log(params)
      this.$http.post('/api/v1/purchase-payment-request/confirm',params)
        .then(result => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose()
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }).catch(e => {

        })
    },
    handleSubmit1() {
      if (this.paymentProposal.length < 1) {
        this.$vs.notify({
          title: "Error",
          text: "please select payment proposal first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return
      }
      if (this.operatingUnitBank == null){
         this.$vs.notify({
          title: "Error",
          text: "please select operating unit bank first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
      
      // //console.log(this.operatingUnitBank,this.supplierBank)
      
      let line = []
      let total = parseFloat(0)
      this.paymentProposal.map(v => {
        total += parseFloat(v.PaidValue)
        line.push({
          proposal_id: v.ID,
          code: v.Code
        })
      })
      const params = {
        id:this.selected.ID,
        purchase_payment_request_id: this.selected.ID,
        status_purchase_request: 3,
        reference_code:this.reference_code,
      }
      this.$vs.loading();
      //console.log(params)
      this.$http.post('/api/v1/purchase-payment-request/confirm',params)
        .then(result => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose()
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }).catch(e => {

        })
    },
    handleFailed() {
      // //console.log(this.operatingUnitBank,this.supplierBank)
      
      let line = []
      let total = parseFloat(0)
      this.paymentProposal.map(v => {
        total += parseFloat(v.PaidValue)
        line.push({
          proposal_id: v.ID,
          code: v.Code
        })
      })
      const params = {
        id:this.selected.ID,
        purchase_payment_request_id: this.selected.ID,
        status_purchase_request: 4,
        reference_code:this.reference_code,
      }
      this.$vs.loading();
      //console.log(params)
      this.$http.post('/api/v1/purchase-payment-request/change-status',params)
        .then(result => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose()
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }).catch(e => {

        })
    },
    handleCancel() {
      // //console.log(this.operatingUnitBank,this.supplierBank)
      
      let line = []
      let total = parseFloat(0)
      this.paymentProposal.map(v => {
        total += parseFloat(v.PaidValue)
        line.push({
          proposal_id: v.ID,
          code: v.Code
        })
      })
      const params = {
        id:this.selected.ID,
        purchase_payment_request_id: this.selected.ID,
        status_purchase_request: 6,
        reference_code:this.reference_code,
      }
      this.$vs.loading();
      //console.log(params)
      this.$http.post('/api/v1/purchase-payment-request/change-status',params)
        .then(result => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose()
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }).catch(e => {

        })
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    handleShowAttachment(selected) {
        console.log(selected)
        if (selected.path != "" && selected.path !== undefined) {
          window.open(selected.path);
        } else if (selected.PathFile != "" && selected.PathFile !== undefined) {
          window.open(selected.PathFile);
        }

    },
    handleDeleteAttachment(index, id) {
      this.fileAttachment = this.fileAttachment.filter((item, i) => i != index);
      this.deleteFile(id);
      console.log(this.fileAttachment);
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.selected.ID);
      form.append("file", this.file[array]);
      return form;
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/purchase-payment-request/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    uploadData(array) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/purchase-payment-request/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.fileAttachment.push({
            id: resp.data.attachment.ID,
            name: resp.data.attachment.NameFile,
            path: resp.data.attachment.PathFile,
          });
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleAttachment() {
      
      if (this.$refs.file.files.length > 0) {
        
        this.file = this.$refs.file.files;
        for(let i =0; i < this.$refs.file.files.length; i++) {
          
          this.uploadData(i);
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    getOptionPaymentProposal(query) {
      //console.log(this.supplier)
      this.$http.get('/api/v1/purchase-payment-proposal',{
            params: {
                status: 1,
                request_code: this.selected.Code,
                search: query,
                length: 100,
                still_empty: 1,
                // page: params.page,
                // order: params.sort,
                // sort: params.order,
                supplier_code: this.supplier.Code
            }
        }).then((r) => {
          this.optionPaymentProposal = r.data.purchasePaymentProposal
          //console.log(r)
        }).catch((e) => {
          //console.log(e)
        })
    },
    getOptionBankOperationUnit() {
      this.$http.get('/api/v1/purchase-payment-proposal/bank-operating-unit').then((r) => {
          this.optionBankOperatingUnit = r.data.dataOperatingUnitBank
          this.bank = r.data.dataBank
          if (this.selected.ID) {
            r.data.dataOperatingUnitBank.map(v => {
              console.log("this.selected.ToBankName: ", v.AccountNumber == this.selected.AccountNumber)
              if (v.AccountNumber == this.selected.AccountNumber) {
                  this.operatingUnitBank = v
              }
            }) 
          }
        }).catch((e) => {
          //console.log(e)
        })
    },
    getOptionSupplierBank() {
      this.$http.get('/api/v1/pi/supplier-account/' + this.supplier.ID).then((r) => {
        this.optionBankSupplier = r.data.account
        //console.log(this.selected)
        if (this.selected.ID) {
          r.data.account.map(v => {
             if (v.AccountNumber == this.selected.ToAccountNumber) {
                  this.supplierBank = v
            }
          }) 
        }
        //console.log(r)
      }).catch((e) => {
        //console.log(e)
      })

    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/purchase-payment-request/line/" + this.selected.ID)
        .then(result => {
          // this.$vs.loading.close();
          this.paymentProposal = []
          result.data.purchasePaymentProposal.map( v => {
            this.paymentProposal.push(v)
          })
          console.log(result.data.attechment, 'attechment')
          this.purchaseInvoice = []
          result.data.purchaseInvoice.map( v => {
            this.purchaseInvoice.push(v)
          })
          this.fileAttachment = []
          result.data.attechment.map( v => {
             this.fileAttachment.push({
                id: v.ID,
                name: v.NameFile,
                path: v.PathFile,
              });
          })
          this.notes = this.selected.Notes.toString().split(";")
          //console.log(result)
        })
        .catch(e => {
          this.$vs.loading.close();

        })
    },
    getOptionSupplier(query) {
      if (query.length <= 2) {
        return
      }
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
            search: query,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
            if (this.selected.ID) {
              resp.data.records.map(v => {
                if (v.Code == this.selected.SupplierCode) {
                  this.supplier = v
                }
              })
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    this.getOptionBankOperationUnit();
    this.getData()
    this.getOptionSupplier(this.selected.SupplierName)
    this.new_notes = this.selected.SupplierCode + " " + this.selected.SupplierName
    this.date_clearing = new Date(moment())
    // this.getOptionPaymentTerm();
    // this.getOptionSupplier();
    // this.getOptionWarehouse();
  },
  computed: {},
  watch: {
    "supplier.ID"(){ 
      this.getOptionSupplierBank()
    },
    "selected.ID"() {
      //console.log("selected change")
      this.getData()
      this.getOptionSupplier(this.selected.SupplierName)
    }
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>
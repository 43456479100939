var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Payment"}},[_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + 'md:w-1/4 w-full mb-base' : '',
        _vm.detailHide,
      ]},[_c('vs-tabs',{attrs:{"color":_vm.colorx}},[_c('vs-tab',{attrs:{"label":"Proposal Approved"},on:{"click":function($event){_vm.colorx = 'danger'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('proposal')],1)]),_c('vs-tab',{attrs:{"label":"Open"},on:{"click":function($event){_vm.colorx = 'danger'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('open')],1)]),_c('vs-tab',{attrs:{"label":"Waiting"},on:{"click":function($event){_vm.colorx = 'success'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('waiting')],1)]),_c('vs-tab',{attrs:{"label":"Payment Execute"},on:{"click":function($event){_vm.color = 'success'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('execute')],1)]),_c('vs-tab',{attrs:{"label":"Payment Confirm"},on:{"click":function($event){_vm.colorx = 'success'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('confirm')],1)]),_c('vs-tab',{attrs:{"label":"Failed"},on:{"click":function($event){_vm.colorx = 'success'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('failed')],1)]),_c('vs-tab',{attrs:{"label":"Reversed"},on:{"click":function($event){_vm.colorx = 'success'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('reversal')],1)]),_c('vs-tab',{attrs:{"label":"Rejected"},on:{"click":function($event){_vm.colorx = 'success'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('rejected')],1)]),_c('vs-tab',{attrs:{"label":"Cancel"},on:{"click":function($event){_vm.colorx = 'success'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('canceled')],1)])],1)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? _vm.detailShow + 'md:w-3/4 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close")])],1),_c('vs-col',{staticClass:"vertical-divider",attrs:{"vs-offset":"0","vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"1"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}})],1),_c('create',{on:{"close":_vm.handleClose}})],1)],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }